<template>
	<div class="page">
		<div >
			<el-row>
				<el-col>
					<span class="label">版本号：</span>
					<el-input v-model="versionCode"></el-input>
				</el-col>
			</el-row>
		</div>
		<div style="margin-top:40px;">
			<el-row>
				<el-col>
					<span class="label">版本说明：</span>
					<el-input v-model="versionIntro" type="textarea" rows="10"></el-input>
				</el-col>
			</el-row>
		</div>
		<div style="margin-top:40px;">
			<el-row>
				<el-col>
					<el-button type="primary" style="width:100px;" @click="submit">提交</el-button>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>

	export default {
		data() {
			return {
				versionCode: "",
				versionIntro: ""
			}
		},
		mounted() {
		},
		methods: {
			submit() {
				if(!this.versionCode||!this.versionIntro){
					this.$message({
						message: '错误！',
						type: 'error'
					});
				}
				let params = {
					"versionCode": this.versionCode,
					"versionIntro": this.versionIntro
				}
				console.log(params)
				let loading = this.$loading()
				this.$http.post('/business/Version/Add', params).then(res => {
					loading.close();
					if (res.code == 0) {
						this.$message({
							message: '操作成功！',
							type: 'success'
						});
						return ;
					}
				})

			}
		}
	}
</script>
<style scoped lang="scss">
	.page{
		height: 600px;background-color: #fff;padding:100px 200px;
		.label{
			line-height: 40px;
			display: block;
		}
	}
</style>
